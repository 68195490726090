<template>
	<div class="flex flex-row flex-grow">
		<Menu v-on:callMethodInParent="reset()"></Menu>
		<div class="flex flex-col flex-grow max-w-full w-11/12">
			<main class="px-4 py-6 main sm:p-14">
				<div class="hidden sm:block">
					<h2 class="text-center text-dark mb-12 text-2xl font-bold" v-if="capcha && getTitle">{{ getTitle }}</h2>

					<reCapcha v-if="!capcha" />
					<div class="step" v-else>
						<template v-for="(item, blockIndex) in survey">
							<div class="box mt-11" v-for="(anketa, index) in item" :key="index + copyText" v-show="index == questionNumber">
								<div class="text-gray-300 font-semibold mb-2">Вопрос {{ index + 1 }} / {{ item.length }}</div>
								<div class="flex answer-box">
									<div class="title flex font-bold mb-2 w-6/12">
										<!-- <span class="text-gray-300 text-base mr-2">{{index + 1}}.</span> -->
										<h3 class="text-base text-black font-semibold">{{ !$route.params.id ? anketa.comment : anketa.title }}</h3>
									</div>
									<div class="ask pl-8 w-6/12" :key="anketa.id">
										<div class="radio mb-6" v-for="(answer, ind) in anketa.answers" :key="answer.id">
											<div v-if="answer.title == 'Да/Нет'" class="flex">
												<input
													:id="`input-${answer.id}-1`"
													v-model="anketa.value"
													@change="inlineSave(blockIndex, anketa.id, answer.dependencyQuestions)"
													type="radio"
													:name="`group-${anketa.id}`"
													value="true"
												/>
												<label class="radio-label mr-4" :for="`input-${answer.id}-1`">Да</label>
												<input
													:id="`input-${answer.id}-2`"
													v-model="anketa.value"
													@change="inlineSave(blockIndex, anketa.id, answer.dependencyQuestions)"
													type="radio"
													:name="`group-${anketa.id}`"
													value="false"
												/>
												<label class="radio-label" :for="`input-${answer.id}-2`">Нет</label>
											</div>
											<div v-else>
												<div class="checkbox-btn" v-if="answer.type === 4">
													<input
														:id="`input-${answer.id}`"
														v-model="anketa.value"
														@change="inlineSave(blockIndex, anketa.id, answer.dependencyQuestions)"
														type="checkbox"
														:name="`group-${anketa.id}`"
														:value="true"
													/>
													<label v-if="answer.title != 'Ваши предложения'" class="radio-label" :for="`input-${answer.id}`"
														><p>{{ answer.title }}</p></label
													>
												</div>
												<div class="radio-btn" v-if="answer.type === 3">
													<input
														:id="`input-${answer.id}`"
														v-model="anketa.value"
														@change="inlineSave(blockIndex, anketa.id, answer.dependencyQuestions)"
														type="radio"
														:name="`group-${anketa.id}`"
														:value="answer.value ? answer.value : ind ? 'false' : 'true'"
													/>
													<label v-if="answer.title != 'Ваши предложения'" class="radio-label" :for="`input-${answer.id}`"
														><p>{{ answer.title }}</p></label
													>
												</div>
												<div v-if="answer.type == 1">
													<textarea
														class="textarea"
														:id="`input-${answer.code}`"
														v-model="anketa.value"
														@change="inlineSave(blockIndex, anketa.id, answer.dependencyQuestions)"
													></textarea>
												</div>
											</div>
										</div>
										<div class="flex">
											<button
												@click="nextAnswerButton(blockIndex, index - 1)"
												v-if="index"
												class="btn text-center bg-white border-2 border-dopBlue py-3.5 text-dopBlue text-base w-5/12 rounded-xl font-bold mr-4"
											>
												Назад
											</button>
											<button
												@click="nextAnswerButton(blockIndex, index + 1)"
												v-if="index + 1 !== item.length"
												class="btn text-center py-3.5 w-8/12 rounded-xl text-base font-bold bg-dopBlue text-white"
											>
												Далее
											</button>
											<button
												v-else
												@click="saveBtn()"
												class="btn text-center bg-dopBlue py-3.5 text-white w-8/12 rounded-xl text-base font-bold ml-2"
												:disabled="sending"
												:class="sending ? 'bg-gray-100 text-gray-200' : ''"
											>Отправить</button>
										</div>
										<p class="error" v-show="error">{{ disabled ? 'Выберите медицинскую организацию' : 'Выберите ответ' }}</p>
									</div>
								</div>
							</div>
						</template>
					</div>
				</div>
			</main>
		</div>
	</div>
</template>

<script>
/* eslint-disable */
import Menu from '@/components/Menu.vue';
import debounce from 'lodash.debounce';
import reCapcha from '@/components/reCapcha.vue';
import { getters, actions, mutations } from '@/store/store';

export default {
	name: 'Questionnaire',
	components: {
		reCapcha,
		Menu
	},
	data() {
		return {
			copy: false,
			popup: false,
			accept: false,
			error: false,
			anketsCount: 0,
			copyText: '',
			routeRegion: true,
			closeIds: []
		};
	},
	computed: {
		...getters
	},
	methods: {
		...actions,
		...mutations,
		nextAnswerButton(blockIndex, page) {
			if (this.questionNumber < page) {
				this.accept = false;
			} else {
				this.deleteAnswersToQuestion(blockIndex, this.questionNumber);
				this.accept = true;
			}

			this.nextAnswer(page);
		},
		deleteAnswersToQuestion(blockIndex, number) {
			this.survey[blockIndex][number].value = null;
		},
		saveDebounceTN: debounce(function (value, type) {
			if (type === 'number' && (value < 0 || value > 10)) {
				this.$toast.warning('Можно вводить числа в диапазоне от 0 до 10', {
					position: 'top-right',
					timeout: 3000,
					closeOnClick: true,
					pauseOnFocusLoss: true,
					pauseOnHover: true,
					draggable: true,
					draggablePercent: 0.6,
					showCloseButtonOnHover: false,
					hideProgressBar: true,
					closeButton: 'button',
					icon: true,
					rtl: false
				});

				return;
			}
		}, 1000),
		inlineSave(blockIndex, questionId, dependencyQuestions) {
			let dependency = [];

			dependencyQuestions.forEach((item) => {
				dependency.push(item.dependencyQuestionId);
			});

			this.mutationDependencyQuestions(blockIndex, questionId, dependency);
		},
		saveOne(anketaQuestionId, anketaAnswerId, value, anketaId) {
			this.$http.post('/Anketa/PostAnswer', {
				anketaQuestionId,
				anketaAnswerId,
				value,
				anketaId
			});
		},
		saveBtn() {
			this.survey.forEach((item) => {
				item.forEach((it) => {
					if (it.value) {
						it.answers.forEach((answer) => {
							this.saveOne(answer.anketaQuestionId, answer.id, it.value, this.$route.params.id);
						});
					}
				});
			});

			this.$toast.success('Ответы успешно сохранены!', {
				position: 'top-right',
				timeout: 2000,
				closeOnClick: true,
				pauseOnFocusLoss: true,
				pauseOnHover: true,
				draggable: true,
				draggablePercent: 0.6,
				showCloseButtonOnHover: false,
				hideProgressBar: false,
				closeButton: 'button',
				icon: true,
				rtl: false
			});

			this.resetAnketTwo();
		}
	},
	mounted() {
		this.getAnketa(this.$route.params.id, true);
	}
};
</script>

<style scoped>
.popup-wrapp {
	background: rgba(106, 110, 131, 0.5);
	z-index: 99999;
}

.popup {
	@apply rounded-xl bg-white shadow-default m-auto overflow-hidden;
	width: 650px;
	max-width: 90%;
}

.special .popup {
	width: 90%;
}

.popup-header {
	@apply py-6 px-8 bg-gray-100 flex justify-between items-center;
}

.popup-header h3 {
	@apply text-lg font-extrabold;
}

.popup-label {
	@apply text-textGray text-sm font-bold mb-5 sm:mb-0;
}

.popup-label span {
	@apply text-base font-bold text-black;
}

.popup-content {
	@apply p-4 sm:p-8 sm:pl-10;
}

.popup-content h2 {
	@apply text-xl sm:text-3xl font-extrabold mb-12;
}

.popup-item {
	@apply justify-between items-center mb-10;
}

.popup-input {
	@apply w-full py-3 px-6 border border-gray-100 rounded-lg sm:rounded-r-none sm:border-r-0 mb-2 sm:mb-0;
}

.popup-input:focus {
	outline: 0;
}

.btn-outline {
	width: 168px;
	@apply text-base font-extrabold py-3 text-blue-500;
	min-width: 168px;
	border: 2px solid #3377ff;
}

.special .btn-outline {
	width: 30%;
}

.btn-outline:hover {
	@apply text-white bg-blue-500;
}

.label-lg {
	@apply text-sm pb-2.5 font-bold;
	color: #a8b1ce;
}

.textarea {
	@apply w-full border resize-none rounded-xl h-20 mt-2.5 p-2;
}

.textarea:focus {
	outline: 0 !important;
}

.main {
	max-width: 1125px;
}

.box {
	@apply py-6 px-4 shadow-default rounded-xl sm:px-12 sm:py-6 bg-white sm:mb-6;
}

.label {
	@apply text-sm font-bold mb-3.5;
}

.value {
	@apply text-base mb-8 text-black font-medium;
}

.radio input {
	display: none;
}

.radio .radio-label {
	@apply leading-6 cursor-pointer;
	display: flex;
	position: relative;
	width: 100%;
}

.text-label {
	@apply leading-6;
}

.radio .radio-label::before {
	content: '';
	width: 20px;
	min-width: 20px;
	height: 20px;
	margin-right: 14px;
	display: block;
	border-radius: 50%;
	margin-top: 3px;
	border: 1px solid #a8b1ce;
}

.radio input:checked + .radio-label:after {
	content: '';
	display: block;
	position: absolute;
	left: 3px;
	top: 6px;
	border-radius: 50%;
	background: #3377ff;
	width: 14px;
	height: 14px;
}

.special .answer-box {
	flex-direction: column;
}

.special .title {
	width: 100%;
	margin-bottom: 40px;
}

.special .ask {
	width: 100%;
}

.radio .checkbox-btn input:checked + .radio-label:after {
	border-radius: 0;
}

.radio .checkbox-btn .radio-label::before {
	border-radius: 0;
}

.value-lg {
	@apply font-medium text-base;
}

.special .btn {
	border: 1px solid #000;
}

@media (max-width: 600px) {
	.buttons {
		@apply fixed bottom-2 left-2 right-2;
	}

	.btn {
		@apply w-full;
	}
}
</style>
